import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "../../../helpers/withRouter"
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import bagIcon from "../../../assets/images/icons/bag.png"
import packageIcon from "../../../assets/images/icons/package.png"
import fileEditIcon from "../../../assets/images/icons/file-edit.png"
import luggageIcon from "../../../assets/images/icons/luggage.png"
import pieChartIcon from "../../../assets/images/icons/pie-chart.png"
import dashboardIcon from "../../../assets/images/icons/dashboard-icon.png"
// import returnIcon from "../../../assets/images/icons/return-icon.png"
import refundIcon from "../../../assets/images/icons/refund-icon.png"
import IconTint from 'react-icon-tint';

const SidebarContent = props => {
  const role = useSelector((state) => state.Auth.role);

  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }
    
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();

  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <StyledSimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Dashboard")} </li>
            <li>
              <Link to={`/${role}/dashboard`} >
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li> */}

            <li className="menu-title">{props.t("Menu")}</li>

            {/* SIDE BAR BASE ON ROLE START*/}

            { //////////////// ADMIN SIDEBAR ////////////////
              role === "admin" && <>
                <li>
                  <Link to={`/${role}/dashboard`}>
                    <IconTint src={dashboardIcon} color={path.pathname === `/${role}/dashboard` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${role}/product-order/list`}>
                    <IconTint src={bagIcon} color={path.pathname === `/${role}/product-order/list` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Product Order")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to={`/${role}/service-order/list`}>
                    <IconTint src={bagIcon} color={path.pathname === `/${role}/service-order/list` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Service Order")}</span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to={`/${role}/return-order/list`}>
                    <IconTint src={returnIcon} alt="sideBarGenericIcon" color={path.pathname === `/${role}/return-order/list` ? "#cecece" : "#545a6d" }/>
                    <span>{props.t("Return Order")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to={`/${role}/return-and-refund-order/list`}>
                    <IconTint src={refundIcon} alt="sideBarGenericIcon" color={path.pathname === `/${role}/return-and-refund-order/list` ? "#cecece" : "#545a6d" }/>
                    <span>{props.t("Return & Refund Order")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${role}/cancellation-and-refund-order/list`}>
                    <IconTint src={refundIcon} alt="sideBarGenericIcon" color={path.pathname === `/${role}/cancellation-and-refund-order/list` ? "#cecece" : "#545a6d" }/>
                    <span>{props.t("Cancellation & Refund Order")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${role}/product-management/list`}>
                    <IconTint src={packageIcon} color={path.pathname === `/${role}/product-management/list` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Product Management")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to={`/${role}/service-management/list`}>
                    <IconTint src={luggageIcon} color={path.pathname === `/${role}/service-management/list` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Service Management")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to={`/${role}/article-management/list`}>
                    <img src={fileEditIcon} alt="sideBarGenericIcon"/>
                    <span>{props.t("Article Management")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to={`/${role}/pet-category/list`}>
                    <img src={fileEditIcon} alt="sideBarGenericIcon"/>
                    <span>{props.t("Pet Category Management")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to={`/${role}/users-management/list`}>
                    <i className="bx bx-user fs-2"></i>
                    <span>{props.t("User Management")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to={`/${role}/voucher-management`}>
                    <i className="bx bx-user fs-2"></i>
                    <span>{props.t("Voucher Management")}</span>
                  </Link>
                </li> */}
              </>
            }

            { //////////////// MERCHANT SIDEBAR CONTENT ////////////////
              role === "merchant" && <>
                <li>
                  <Link to={`/${role}/dashboard`}>
                    <IconTint src={dashboardIcon} color={path.pathname === `/${role}/dashboard` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${role}/product-order/list`}>
                    <IconTint src={bagIcon} color={path.pathname === `/${role}/product-order/list` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Product Order")}</span>
                  </Link>
                </li>

                {/* <li>
                  <Link to={`/${role}/service-order/list`}>
                    <IconTint src={bagIcon} color={path.pathname === `/${role}/service-order/list` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Service Order")}</span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to={`/${role}/return-order/list`}>
                    <IconTint src={returnIcon} alt="sideBarGenericIcon" color={path.pathname === `/${role}/return-order/list` ? "#cecece" : "#545a6d" }/>
                    <span>{props.t("Return Order")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to={`/${role}/return-and-refund-order/list`}>
                    <IconTint src={refundIcon} alt="sideBarGenericIcon" color={path.pathname === `/${role}/return-and-refund-order/list` ? "#cecece" : "#545a6d" }/>
                    <span>{props.t("Return & Refund Order")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${role}/cancellation-and-refund-order/list`}>
                    <IconTint src={refundIcon} alt="sideBarGenericIcon" color={path.pathname === `/${role}/cancellation-and-refund-order/list` ? "#cecece" : "#545a6d" }/>
                    <span>{props.t("Cancellation & Refund Order")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${role}/product-management/list`}>
                    <IconTint src={packageIcon} color={path.pathname === `/${role}/product-management/list` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Product Management")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${role}/service-management/list`}>
                    <IconTint src={luggageIcon} color={path.pathname === `/${role}/service-management/list` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Service Management")}</span>
                  </Link>
                </li>
                {/* 
                
                <li>
                  <Link to={`/${role}/booking-management/calendar-view`}>
                    <IconTint src={fileEditIcon} color={path.pathname === `/${role}/booking-management/calendar-view` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Booking Management")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to={`/${role}/reports`}>
                    <IconTint src={pieChartIcon} color={path.pathname === `/${role}/reports` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Reports")}</span>
                  </Link>
                </li>
              </>
            }

            {
              role === "marketing" && <>
                <li>
                  <Link to={`/${role}/dashboard`}>
                    <IconTint src={dashboardIcon} color={path.pathname === `/${role}/dashboard` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${role}/product-order/list`}>
                    <IconTint src={bagIcon} color={path.pathname === `/${role}/product-order/list` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Product Order")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${role}/return-and-refund-order/list`}>
                    <IconTint src={refundIcon} alt="sideBarGenericIcon" color={path.pathname === `/${role}/return-and-refund-order/list` ? "#cecece" : "#545a6d" }/>
                    <span>{props.t("Return & Refund Order")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${role}/cancellation-and-refund-order/list`}>
                    <IconTint src={refundIcon} alt="sideBarGenericIcon" color={path.pathname === `/${role}/cancellation-and-refund-order/list` ? "#cecece" : "#545a6d" }/>
                    <span>{props.t("Cancellation & Refund Order")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${role}/product-management/list`}>
                    <IconTint src={packageIcon} color={path.pathname === `/${role}/product-management/list` ? "#cecece" : "#545a6d" } />
                    <span>{props.t("Product Management")}</span>
                  </Link>
                </li>
              </>
            }

            {/* SIDE BAR BASE ON ROLE END*/}

          </ul>
        </div>
      </StyledSimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));

const StyledSimpleBar = styled(SimpleBar)`
  #sidebar-menu span {
    font-weight: 500;
    /* color: #7f8387; */
  }

  #sidebar-menu span:hover {
    color: #ffff;
  }

  #sidebar-menu span:active {
    color: #ffff;
  }

  #sidebar-menu .mm-active {
    color: red !important;
  }

  #sidebar-menu ul li {
    padding: 5px;
  }

  #sidebar-menu ul li a {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  #sidebar-menu ul li span {
    font-size: 12px;
  }
`